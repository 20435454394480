import { TOTAL_SCREENS } from './commonUtils'
import { Subject } from 'rxjs'

export default class ScrollService {
  static scrollHandler = new ScrollService()

  static currentScreenBroadcaster = new Subject()
  static currentScreenFadeIn = new Subject()

  constructor () {
    window.addEventListener('scroll', this.checkCurrentScreenUnderViewport)
  }
  scrollToHireMe = () => {
    let contactMeScreen = document.getElementById('CONTACT ME')
    if (!contactMeScreen) {
      return
    } else {
      contactMeScreen.scrollIntoView({ behavior: 'smooth' })
    }
  }

  scrollToHome = () => {
    let homeScreen = document.getElementById('HOME')
    if (!homeScreen) {
      return
    } else {
      homeScreen.scrollIntoView({ behavior: 'smooth' })
    }
  }

  isElementInView = (elem, type) => {
    let rec = elem.getBoundingClientRect()
    let elementTop = rec.top
    let elementBottom = rec.bottom

    let partiallyVisible = elementTop < window.innerHeight && elementBottom >= 0
    let completelyVisible =
      elementTop >= 0 && elementBottom <= window.innerHeight

    switch (type) {
      case 'partial':
        return partiallyVisible

      case 'complete':
        return completelyVisible

      default:
        return false
    }
  }

  checkCurrentScreenUnderViewport = event => {
    if (!event || event.length < 1) {
      return
    } else {
      for (let screen of TOTAL_SCREENS) {
        let screenFromDom = document.getElementById(screen.screen_name)
        if (!screenFromDom) {
          continue
        }

        let fullyVisible = this.isElementInView(screenFromDom, 'complete')
        let partiallyVisible = this.isElementInView(screenFromDom, 'partial')
        if (fullyVisible || partiallyVisible) {
          if (partiallyVisible && !screen.alreadyRendered) {
            ScrollService.currentScreenFadeIn.next({
              fadeInScreen: screen.screen_name
            })
            screen['alreadyRendered'] = true
            break
          }
        }
        if (fullyVisible) {
          ScrollService.currentScreenBroadcaster.next({
            screenInView: screen.screen_name
          })
          break
        }
      }
    }
  }
}
