import React, { useState } from 'react'
import Typical from 'react-typical'
import './Contact.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import imgBack from '../../../src/images/mailz.jpeg'
import load1 from '../../../src/images/load2.gif'

import ScrollService from '../../utilities/ScrollService'
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading'
import Animations from '../../utilities/Animations'

export default function Contact (props) {
  let fadeInScreenHandler = screen => {
    if (screen.fadeInScreen !== props.id) {
      return
    } else {
      Animations.animations.fadeInScreen(props.id)
    }
  }

  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(
    fadeInScreenHandler
  )

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [banner, setBanner] = useState('')
  const [bool, setBool] = useState(false)

  const handleName = e => {
    setName(e.target.value)
  }
  const handleEmail = e => {
    setEmail(e.target.value)
  }
  const handleMessage = e => {
    setMessage(e.target.value)
  }

  const submitForm = async e => {
    e.preventDefault()
    try {
      let data = {
        name,
        email,
        message
      }
      setBool(true)
      const res = await axios.post(
        `https://yujies-website.herokuapp.com/contact`,
        data
      )
      if (name.length === 0 || email.length === 0 || message.length === 0) {
        setBanner(res.data.msg)
        toast.error(res.data.msg)
        setBool(false)
      } else if (res.status === 200) {
        setBanner(res.data.msg)
        toast.success(res.data.msg)
        setBool(false)
      } else {
        console.log(res.status)
        setBanner(res.status)
        toast.success(res.data.msg)
        setBool(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='main-container fade-in' id={props.id || ''}>
      <ScreenHeading subHeading={"Let's Keep In Touch"} title={'Contact Me'} />
      <div className='central-form'>
        <div className='col'>
          <h2>
            <Typical loop={Infinity} steps={['Get In Touch', 1000]} />
          </h2>
          <a href='https://t.me/bleepboopbloop'>
            <i className='fa fa-phone-square fa-2x'></i>
          </a>
          <a href='https://github.com/therealcs1010'>
            <i className='fa fa-github-square fa-2x'></i>
          </a>
          <a href='https://www.linkedin.com/in/sim-yu-jie/'>
            <i className='fa fa-linkedin-square fa-2x'></i>
          </a>
        </div>
        <div className='back-form'>
          <div className='img-back'>
            <h4>Send Your Email Here</h4>
            <img src={imgBack} alt='image not found'></img>
          </div>
          <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor='name'>Name</label>
            <input type='text' onChange={handleName} value={name} />

            <label htmlFor='email'>Email</label>
            <input type='email' onChange={handleEmail} value={email} />

            <label htmlFor='message'>Message</label>
            <textarea type='text' onChange={handleMessage} value={message} />

            <div className='send-btn'>
              <button type='submit'>
                {bool ? '' : 'SEND'}
                {bool ? '' : <i className='fa fa-paper-plane' />}
                {bool ? (
                  <b className='load'>
                    <img src={load1} alt='image not responding' />
                  </b>
                ) : (
                  ''
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
